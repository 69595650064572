<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{ $route.query.merchant }} Parcel summary of current month</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body mr-20">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <div style="width:100%; overflow-x:auto;">
                                            <table class="table table-bordered table-striped table-sm text-center">
                                                <thead>
                                                    <tr>
                                                        <th>SL</th>
                                                        <th>Date</th>
                                                        <th>Total Parcel</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>SL</th>
                                                        <th>Date</th>
                                                        <th>Total Parcel</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <tr v-for="(item,key) in listData" :key="key">
                                                        <td>{{ key+1 }}</td>
                                                        <td>{{ item.date | dateformat }}</td>
                                                        <td>{{ item.total_parcel}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
    import config from '@/config'
    import moment from 'moment'
    export default {
        name:'ParcelSummaryDetails',
        data () {
            return { 
                loader: false,   
                search: {
                    merchant_id: this.$route.query.merchant_id,
                    start_date: moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD')
                },
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created () {
            this.loadData()
        },
        computed : {
            authUser () {
                return this.$store.state.authUser
            },
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            commonObj () {
                return this.$store.state.commonObj
            }
        },
        methods:{
            searchData () {
                this.loadData()
            },
            async loadData(){   
                this.loader = true 
                const response = await config.getData(`/merchant/parcel-summary-details/${this.$route.query.merchant_id}`)
                this.loader = false
                if (response.success) {
                    this.$store.dispatch('setList', response.data)
                } else {
                    this.$store.dispatch('setList', [])
                    
                }
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>